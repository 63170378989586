<template>
  <div class="main-page">
    <Constructor :items="dynamicComponents.slice(0, 1)" />
    <Stories />
    <Constructor :items="dynamicComponents.slice(1)" />
    <InstagramFeed v-if="feed" :feed="feed" />
  </div>
</template>

<script>
import {mapActions} from 'vuex';
import Constructor from '~/components/catalog/constructor/index.vue';
import pageMeta from '~/mixins/pageMeta';
import InstagramFeed from '~/components/catalog/instagramFeed/index.vue';
import Stories from '~/components/catalog/stories/index.vue';

export default {
  components: {Stories, InstagramFeed, Constructor },
  mixins: [pageMeta],
  async asyncData({ $api }) {
    const [ meta, constructor ] = await Promise.all([
      $api.general.getPageMeta(),
      $api.general.getPageConstructor(''),
    ])

    return {
      pageTitle: meta?.title || '',
      pageMetaData: meta?.data || [],
      dynamicComponents: constructor?.data || [],
    };
  },
  data() {
    return {
      feed: null,
    }
  },
  async mounted() {
    this.getConstructor();
    this.getCartItemsCached();

    try {
      this.feed = await this.$api.general.getInstagramFeed() || null
    } catch (e) {
      console.log(e);
    }
  },
  methods: {
    ...mapActions('cart', ['getCartItemsCached']),
    getConstructor() {
      return this.dynamicComponents;
    },
  },
};
</script>
