import env from '@/.env.json';

export default {
  head() {
    const scripts = []

    if(this.pageScripts?.length) {
      scripts.push(...this.pageScripts)
    }

    const meta = this.getPageMetaData()

    const head = {
      title: `${this.pageTitle || ''} | ${env.APP_TITLE}`,
    }

    if (meta.length) {
      head.meta = meta
    }

    if (scripts.length) {
      head.scripts = [ ...scripts ]
    }

    return head
  },
  methods: {
    getPageMetaData() {
      if (!Array.isArray(this.pageMetaData)) return []

      return this.pageMetaData.map(meta => {
        meta.hid = meta.property || meta?.name

        return meta
      })
    },
  },
}
