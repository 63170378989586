<template>
  <div v-if="feed.length > 0">
    <div class="instagram-logo">
      <img src="/assets/images/instagram-logo.svg" alt="instagram-logo" />
    </div>
    <div
      v-swiper="sliderOptions"
      class="insta-feed _swiper"
    >
      <div class="insta-feed__carousel swiper-wrapper">
        <div
          v-for="item of feed"
          :key="item.id"
          class="swiper-slide"
        >
          <a :href="item.permalink" target="_blank">
            <img
              :src="item.mediaUrl"
              :alt="item.permalink"
            >
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {directive} from 'vue-awesome-swiper';
export default {
  name: 'InstagramFeed',
  directives: {
    swiper: directive,
  },
  components: {
  },
  props: {
    feed: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      sliderOptions: {
        touchRatio: 1,
        observer: true,
        observeParents: true,
        spaceBetween: 0,
        slidesPerView: 'auto',
        centeredSlides: false,
        loop: true,
        watchSlidesVisibility: false,
        watchSlidesProgress: false,
        resistance: true,
        resistanceRatio: 0.7,
        preloadImages: false,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
        lazy: {
          loadOnTransitionStart: false,
          loadPrevNext: true,
        },
      },
    }
  },
}
</script>
<style scoped>
.insta-feed {
  /*overflow: hidden;*/
  /*width: 100%;*/
  /*white-space: nowrap;*/

  .swiper-slide {
    height: 240px;
    width: 240px;
  }
  img {
    /*height: 240px;*/
    /*width: 240px;*/
  }
  .insta-feed__carousel {
    /*margin-left: -240px;*/
    width: fit-content;
  }
}
.instagram-logo {
  width: 100%;
  text-align: center;
  padding-top: 40px;
  padding-bottom: 40px;
}
</style>
