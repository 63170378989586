<template>
  <div class="constructor-page">
    <template v-for="(item, index) in items">
      <div :key="index" :class="[{ 'container' : item.container, 'constructor-page_top' : item.pull }, item.margin]">
        <component
          :is="item.name"
          :key="`component-${item.name}`"
          v-bind="{ ...item.attributes, titleMain: title, tabName: tabName }"
        />
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'Constructor',
  props: {
    items: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    tabName: {
      type: String,
      default: '',
    },
  },
  created() {
    this.items?.forEach(item => {
      const { name } = item

      this.$options.components[name] = () => import(`@/components/elements/${name}.vue`)
    })
  },
}
</script>
